<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list v-if="auth && auth.first_name && auth.last_name && auth.email">
        <v-list-item class="px-2 d-flex justify-center">
          <v-list-item-avatar
            :color="'#' + ((Math.random() * 0xffffff) << 0).toString(16)"
            size="60"
            class="mr-0 d-flex justify-center"
            style="position: relative"
          >
            <div class="white--text text-h5" style="position: absolute">
              {{ auth.display_name.charAt(0).toUpperCase() }}
            </div>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link class="text-center" @click="viewProfile">
          <v-list-item-content class="pt-0">
            <v-list-item-title class="text-h6">
              {{ auth.display_name }}
            </v-list-item-title>
            <v-list-item-subtitle> {{ auth.email }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav>
        <v-sheet
          v-for="item in drawerItems"
          :key="item.title"
          color="transparent"
        >
          <v-list-group
            v-if="item.items && item.canAccess"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link v-for="child in item.items" :key="child.title">
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            link
            v-else-if="item.canAccess"
            :to="{ name: item.pathName }"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-sheet>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app flat class="app-dar" outlined>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-sheet class="d-flex align-center px-5 py-3" color="transparent">
        <v-img max-width="20" alt="dealpartner" src="/images/Icon.png" />
        <v-sheet
          class="text-h6 font-weight-bold primary--text ms-1"
          color="transparent"
          >dealpartner.lk</v-sheet
        >
      </v-sheet>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn>

      <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>

        <v-card outlined elevation="0">
          <v-list class="pb-0">
            <v-list-item>
              <v-list-item-title>Enable dark theme</v-list-item-title>
              <v-list-item-action>
                <v-switch v-model="isDark" dense></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Update app</v-list-item-title>
              <v-list-item-action>
                <v-btn icon @click="$router.go()">
                  <v-icon>mdi-update</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title>Logout</v-list-item-title>
              <v-list-item-action>
                <v-btn icon color="error" @click="logout()">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-banner single-line v-if="!isOnline">
        <div
          class="d-flex justify-center align-center secondary--text text-caption"
        >
          <v-icon slot="icon" color="secondary" class="me-2" small>
            mdi-cloud-off-outline
          </v-icon>
          You are offline.
        </div>
      </v-banner>
      <v-breadcrumbs :items="breadcrumbsItems" divider=".">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact>
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <v-container fluid>
        <router-view :key="$route.fullPath"></router-view>
      </v-container>

      <v-snackbar
        bottom
        left
        v-model="isMessage"
        timeout="5000"
        :vertical="error.isError"
      >
        <v-icon class="me-2" color="error" v-if="error.isError">
          mdi-alert-circle-outline
        </v-icon>
        <v-icon class="me-2" color="primary" v-else>
          mdi-check-circle-outline
        </v-icon>
        {{ error.error }}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="error.isError ? 'error' : 'primary'"
            text
            v-bind="attrs"
            @click="isMessage = false"
          >
            {{ error.isError ? "Close" : "OK" }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <v-footer class="d-flex justify-end foot-dar" outlined>
      <v-sheet
        class="font-weight-light text-caption grey--text"
        color="transparent"
      >
        © 2024, dealpartner.lk. All rights reserved. dealpartner.lk version Beta {{ $version }}
      </v-sheet>
    </v-footer>

    <!-- Delete doc confirmation dialog -->
    <v-dialog v-model="sync" persistent max-width="220">
      <v-card outlined>
        <v-card-text class="d-flex flex-column align-center mt-4">
          Syncing offline changes.
          <v-progress-circular
            :size="80"
            indeterminate
            color="primary"
            width="1"
            class="mt-3"
          >
            <v-icon x-large color="primary"> mdi-sync </v-icon>
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    error: (state) => state.error.data,
    settings: (state) => state.settings.data,
  }),
  components: {
    //
  },
  data: () => ({
    isOnline: navigator.onLine,
    isMessage: false,
    isDark: false,
    breadcrumbsItems: [],
    drawer: window.innerWidth > 1264,
    drawerItems: [],
  }),
  watch: {
    isDark(val) {
      this.$vuetify.theme.dark = val;
      this.$store.commit("updateSettings", {
        isDark: val,
      });
    },
    error(newError) {
      if (newError) {
        this.isMessage = true;
      }
    },
    isMessage(newValue) {
      if (newValue === false) {
        this.$store.dispatch("resetError");
      }
    },
  },
  async created() {
    if (this.settings) {
      this.isDark = this.settings.isDark;
      this.$vuetify.theme.dark = this.settings.isDark;
    } else {
      this.isDark = this.$vuetify.theme.dark;
      this.$store.commit("updateSettings", {
        isDark: this.isDark,
      });
    }
    this.breadcrumbsItems = this.$router.currentRoute.meta.breadcrumbsItems;

    this.drawerItems = [
      {
        action: "mdi-view-dashboard",
        title: "Dashboard",
        pathName: "PageDashboard",
        canAccess: true,
      },
      {
        action: "mdi-account-multiple",
        active: true,
        title: "Staff",
        pathName: "PageStaffList",
        canAccess: true,
      },
      {
        action: "mdi-shield-account",
        title: "Categories",
        pathName: "PageCategoryList",
        canAccess: true,
      },
      {
        action: "mdi-car-multiple",
        title: "Vehicles ",
        pathName: "PageVehicleList",
        canAccess: true,
      },
      // {
      //   action: "mdi-car-multiple",
      //   items: [{ title: "List Item" }],
      //   title: "Vehicles ",
      //   canAccess: true
      // },
    ];
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  updated() {
    this.breadcrumbsItems = this.$router.currentRoute.meta.breadcrumbsItems;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "PageAuthSignin" });
    },
    async updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },
    viewProfile() {
      if (this.$route.name == "PageViewStaff") {
        if (this.$route.path != `/staff/${this.auth.uuid}`) {
          this.$router.replace({
            params: { uuid: this.auth.uuid },
            query: { bc: this.auth.first_name + " " + this.auth.last_name },
          });
          const { fullPath } = this.$route;
          this.$router.replace("/temporary-route").then(() => {
            this.$router.replace(fullPath);
          });
        }
      } else {
        this.$router.push({
          name: "PageViewStaff",
          params: { uuid: this.auth.uuid },
          query: { bc: this.auth.first_name + " " + this.auth.last_name },
        });
      }
    },
  },
};
</script>

<style>
.app-dar {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}

.foot-dar {
  border-bottom: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}
</style>